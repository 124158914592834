<template>
  <base-section id="affiliates" space="36">
    <v-container>
      <base-section-heading title="Who's using FleeTOP?" />

      <v-slide-group>
        <template v-for="n in 7">
          <v-slide-item :key="n" class="align-self-center">
            <base-img
              :src="require(`@/assets/customer${n}.png`)"
              color="grey"
              contain
              height="80"
              width="128"
            />
          </v-slide-item>

          <v-responsive
            v-if="n < 7"
            :key="`divider-${n}`"
            class="text-center"
            height="96"
            width="100"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionAffiliates"
};
</script>
